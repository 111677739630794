
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import 'node_modules/@skillbox/front-box/dist/Avatars/UiAvatar/style';

.resume-v3__wrapper {
  margin-right: 4px;
  margin-left: 4px;
  padding-top: 56px;
  padding-bottom: 56px;
  border-radius: 20px;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 32px;
  }

  @include media(xl) {
    width: calc(100vw - 40px);
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
  }
}

.resume-v3__inner {
  @include media(sm-max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media(xl) {
    display: grid;
    grid-column-gap: 8px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 344px 1fr;
  }
}

.resume-v3__title {
  margin-bottom: 32px;

  @include media(md) {
    width: 428px;
  }

  @include media(lg) {
    grid-row: 1/2;
    grid-column: 1/-1;
    width: 772px;
    margin-bottom: 56px;
  }

  @include media(xl) {
    width: 776px;
  }
}

.resume-v3__block {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--bg-main-color);

  @include media(lg) {
    border-radius: 32px;
    padding: 48px;
  }

  &--person {
    margin-bottom: 8px;

    @include media(md) {
      display: grid;
      grid-column-gap: 16px;
      grid-template-rows: auto 1fr;
      grid-template-columns: 72px 1fr;
    }

    @include media(lg) {
      grid-column-gap: 20px;
    }

    @include media(xl) {
      display: flex;
      flex-direction: column;
      grid-row: 2/-1;
      grid-column: 1/2;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      padding: 32px;
    }
  }

  &--resume {
    @include media(xl) {
      grid-row: 2/-1;
      grid-column: 2/-1;
      padding: 48px;
    }
  }
}

.resume-v3__avatar {
  width: 72px;
  height: 72px;
  margin-bottom: 16px;

  @include media(md, lg-max) {
    margin-bottom: 0;
    grid-row: 1/-1;
    grid-column: 1/2;
  }

  @include media(xl) {
    width: 180px;
    height: 180px;
    margin-bottom: 40px;
  }

  .ui-video,
  .ui-picture {
    height: 100%;
  }

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.resume-v3__subtitle {
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--text-addition-color);

  @include media(md) {
    width: 324px;
  }

  @include media(lg) {
    width: 284px;
  }

  @include media(xl) {
    width: 278px;
  }

  &--job {
    margin-bottom: 8px;

    @include media(md, lg-max) {
      grid-row: 1/2;
      grid-column: 2/-1;
      margin-top: 6px;
    }

    @include media(xl) {
      margin-bottom: 12px;
      text-align: center;
    }
  }
}

.resume-v3__job-title {
  display: block;

  @include media(md, lg-max) {
    grid-row: 2/-1;
    grid-column: 2/-1;
  }

  @include media(xl) {
    text-align: center;
  }
}

.resume-v3__list {
  @include ulres;

  @include media(xl) {
    column-count: 2;
    column-gap: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;

    @include media(lg) {
      margin-bottom: 40px;
    }

    @include media(xl) {
      margin-bottom: 72px;
    }
  }
}

.resume-v3__item {
  position: relative;
  padding-left: 30px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @include media(lg) {
    padding-left: 34px;
  }
}

.resume-v3__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: var(--accent-success-color);

  @include media(lg) {
    width: 24px;
    height: 24px;
  }
}

.resume-v3__instruments {
  @include ulres;

  display: flex;
  flex-wrap: wrap;
  margin-right: -4px;
  margin-bottom: -4px;
}

.resume-v3__instrument {
  margin-right: 4px;
  margin-bottom: 4px;
}

.resume-v3__instrument-button {
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 6px;
  border-radius: 12px;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    padding: 6px 16px 6px 6px;
  }

  &--has-not-instrument-pic {
    padding: 10px 12px;

    @include media(lg) {
      padding: 12px 16px;
    }
  }
}

.resume-v3__instrument-pic {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 4px;

  @include media(lg) {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 8px;
  }
}
